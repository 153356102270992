a
{
  color: #007bff;
  text-decoration: none;
}

.app-body
{
  height: calc(100% - 50px - env(safe-area-inset-bottom));
}

.app-footer
{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border-top: 1px solid #cccccc;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 9999;
}
