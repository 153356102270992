.about-us
{
  width: 100%;
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  padding: 24px;

  .container
  {
    margin: 0;
    padding: 0;
    padding-bottom: 50px;
  }

  p
  {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  h2
  {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .goto-form
  {
    button
    {
      position: absolute;
      bottom: calc(32px + env(safe-area-inset-bottom));
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      z-index: 99999;
      background-color: #007bff;
      border: none;
      padding: 8px;
      border-radius: 100%;
      border: 2px solid transparent;
      display: block;
      box-sizing: border-box;
      cursor: pointer;

      svg
      {
        display: block;
        width: 24px;
        height: 24px;
      }
    }
  }
}
